import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,

    fontFamily: "Montserrat",
    fontWeight: 700,
  },
}))

const CustomButton = props => {
  const classes = useStyles()

  const { className, children } = props

  return (
    <Button
      {...props}
      variant="contained"
      className={clsx(className, classes.root)}
    >
      {children}
    </Button>
  )
}

export default CustomButton
