import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import {
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { LocaleContext } from "../../contexts/LocaleContext"
import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"

import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 220,
    height: "100%",
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },

  media: {
    height: 200,
  },

  content: {
    display: "flex",
    flexDirection: "column",
  },

  title: {
    fontSize: "1.15rem",
    fontWeight: 700,
    textTransform: "uppercase",
    display: "block",
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  subtitle: {
    fontWeight: 700,
    display: "block",
    textDecoration: "none",
    color: theme.palette.common.black,
  },
}))

const AccommodationsCategoryCard = ({ category }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const classes = useStyles()

  return (
    <>
      <Card className={classes.card} variant="outlined" square>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Grid item className={classes.upper}>
            <CardMedia
              className={classes.media}
              image={category.data.image.fluid.src}
              title={category.data.image.alt}
              component={Link}
              to={`/${localizedSlugPrefix(category)}/${
                category.data.parent.document.uid
              }/${category.uid}/`}
            />

            <CardContent>
              <Typography
                className={classes.title}
                component={Link}
                to={`/${localizedSlugPrefix(category)}/${
                  category.data.parent.document.uid
                }/${category.uid}/`}
              >
                {category.data.title.text}
              </Typography>
              <Typography
                className={classes.subtitle}
                component={Link}
                to={`/${localizedSlugPrefix(category)}/${
                  category.data.parent.document.uid
                }/${category.uid}/`}
              >
                <span className="highlight_red">
                  {category.data.subtitle.text}
                </span>
              </Typography>
              <br />
              <Typography
                component="div"
                variant="body1"
                className={classes.content}
              >
                {category.data.intro.text}
              </Typography>
            </CardContent>
          </Grid>
          <Grid item>
            <CardActions>
              <CustomButton
                color="primary"
                component={Link}
                fullWidth
                to={`/${localizedSlugPrefix(category)}/${
                  category.data.parent.document.uid
                }/${category.uid}/`}
              >
                {i18n.viewAccommodations}
              </CustomButton>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default AccommodationsCategoryCard

AccommodationsCategoryCard.propTypes = {
  category: PropTypes.object.isRequired,
}
